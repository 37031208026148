import React, {useState} from 'react';
import { ChromePicker, ColorResult } from '@hello-pangea/color-picker';
import { Button } from 'components/Common/Buttons';


interface ICustomPicker {
    initialColor: string,
    item: any,
    onSave: any,
    onCancel: any,
}

const CustomColorPicker: React.FC<ICustomPicker> = ({initialColor, item, onSave, onCancel}) => {

    const [color, setColor] = useState<any>(initialColor);

    const handleColorChange = (c: ColorResult, item: any) => {
        setColor(c)
    }

    const handleSave = () => {
        onSave(color, item)
    }

    const handleCancel = () => {
        onCancel()
    }

    const pickerStyle = {
        picker: {
            boxShadow: 'none',
        }
    }

    return (
        <div className='bg-white rounded-lg shadow'>
            <div>
                <ChromePicker styles={pickerStyle} color={color} onChange={handleColorChange}/>
            </div>
            <div className='flex gap-4 p-2'>
                <Button
					onClick={handleCancel}
					variant='secondary'
					className='flex-1 border-[#F97316] text-[#F97316] font-xs'>
					Cancel
				</Button>
                <Button 
                    onClick={handleSave} 
                    variant='primary' 
                    className='flex-1 items-center justify-center font-xs'>
					Apply
				</Button>
            </div>
        </div>
    )
}

export default CustomColorPicker;
